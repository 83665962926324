
const Glide = require("vue-glide-js");
const GlideSlide = require("vue-glide-js");
import getSrcs from "@/mixins/getSrcs";
import { Component, Vue } from "vue-property-decorator";

interface Options {
  gap: number;
  type: string;
  autoplay: number;
  rewind: boolean;
  bound: boolean;
  perView?: number;
  breakpoints?: {};
}

@Component({
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
})
export default class MainView extends Vue {
  public images: Array<string> = [];
  public options: Options = {
    gap: 0,
    type: "carousel",
    autoplay: 4000,
    rewind: true,
    bound: true,
    breakpoints: {
      1184: {
        perView: 1,
      },
    },
  };
  public changeToGallery() {
    this.$router.replace({ name: "Gallery" });
  }

  created() {
    this.images = getSrcs();
  }
}
