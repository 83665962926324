const getSrcs = () => {
  const files = require.context("@/assets", false);
  const result = [];
  const fileNames = files
    .keys()
    .map((key) => key.slice(2))
    .reverse();
  fileNames.forEach((fileName) => {
    if (fileName !== "header.jpg") {
      const img = require(`../assets/${fileName}`);
      result.push(img);
    }
  });
  return result;
};
export default getSrcs;
