
import getSrcs from "@/mixins/getSrcs";
import { Component, Vue } from "vue-property-decorator";
const VueGallery = require("vue-gallery");

@Component({
  components: {
    gallery: VueGallery,
  },
})
export default class Gallery extends Vue {
  public images: Array<string> = [];
  public index: null | number = null;

  public changeComponent() {
    this.$router.replace({ name: "Home" });
  }

  created() {
    this.images = getSrcs();
  }
}
