import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueGlide from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";
import VueRouter from "vue-router";
import routes from "./routes.js";

Vue.use(VueGlide);
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes,
});
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
